import React from "react";

const Loader = ({customClass, content}) => {
  return (
    <div className={`c-loader ${customClass}`}>
      <span>{content}</span>
    </div>
  )
}

export default Loader;
