import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import 'react-toastify/dist/ReactToastify.min.css';
import Booking from "./datacontainers/booking";
import ModalComponent from './datacomponents/modal';

function App() {
  return (
    <section className="block block--space block--space-top">
      <div className="o-container md-default-theme">
        <Booking></Booking>
        <ToastContainer
          className="toast-container message-container"
          toastClassName="message"
          draggable={false}
          hideProgressBar
          autoClose={5000}
          theme="colored"
          position="top-center"
          />
        <ModalComponent />
      </div>
    </section>
  );
}

export default App;
