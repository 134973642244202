export const currentDateFormatted = () => {
  return new Date().toISOString().replace(/T.*/,'').split('-').join('-');
}

export const dashFormatted = (date) => {
  return date.replace(/T.*/,'').split('-').join('-');
}

export const formattedDateISO = () => {
  return new Date().toISOString();
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const format = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  );
}

export const formatDate = (date) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    hour12: false,
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return {
    time: new Date(date).toLocaleTimeString("en-us", {
      hour: "2-digit",
      hour12: false,
      minute: "2-digit"
    }),
    trimedTime: new Date(date).getHours(),
    date: new Date(date).toLocaleTimeString("en-us", options),
    shortDate: () => (
      [
        padTo2Digits(new Date(date).getDate()),
        padTo2Digits(new Date(date).getMonth() + 1),
        new Date(date).getFullYear(),
      ].join('/')
    )
  }
}

export const trimTime = (time) => {
  if (!time) {
    return "";
  }

  return time.substring(0, time.length - 3);
}

export const removeDays = (originalDate, days) => {
  let cloneDate = new Date(originalDate.valueOf());
  cloneDate.setDate(cloneDate.getDate() - days);
  return cloneDate;
}

export const addDays = (originalDate, days) => {
  let cloneDate = new Date(originalDate.valueOf());
  cloneDate.setDate(cloneDate.getDate() + days);
  return cloneDate;
}

export const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}

export const diffInDaysFromToday = (date1, date2) => {
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // To calculate the time difference of two dates
  let differenceInTime = date1.getTime() - date2.getTime();
  // To calculate the no. of days between two dates
  let differenceInDays = Math.round(differenceInTime / oneDay);

  if (differenceInDays < 0) {
    return false;
  }

  return differenceInDays;
}

export const buildDatesArray = (selectedDate, startOffset, endOffset ) => {
  const past = () => {
    const offset = startOffset ? Math.abs(parseInt(startOffset, 10)) : 7;

    let dates = [];

    let diffInPast = diffInDaysFromToday(new Date(selectedDate), new Date());

    if (diffInPast !== false) {
      diffInPast = diffInPast + 1;
    }

    if (diffInPast > offset) {
      diffInPast = offset + 1;
    }

    for (let index = 0; index < diffInPast; index++) {
      dates[index] = format(removeDays(selectedDate, index));
    }

    dates.shift();
    dates = dates.reverse()
    return dates;
  }

  const future = () => {
    const offset = endOffset ? Math.abs(parseInt(endOffset, 10)) : 7;

    let dates = [];

    for (let index = 0; index < offset; index++) {
      dates[index] = format(addDays(selectedDate, index+1));
    }

    return dates;
  }

  return [...past(), ...future()];
}
