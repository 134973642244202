import React from "react";

const InfoModal = ({ closeModalHandler, content }) => {
  return (
    <>
      <div className="modalContent-dialogBody">
        <p>{content}</p>
      </div>

      <div className="modalContent-dialogFooter">
        <button
          className="c-button"
          onClick={closeModalHandler}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default InfoModal;
