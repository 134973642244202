import React, {useEffect, useState} from "react";
import { formatDate, dashFormatted } from "../utils/date";
import Loader from "./loader";

const DateRange = (props) => {
  let {data, slots, setSlots, type, types, steps, bookingData, setBookingData, moment} = props;

  const [loading, setLoading] = useState(false);

  const selectSlot = (item) => {
    console.log(item.Date);
    setLoading(true);
    setBookingData({
      ...bookingData,
      ...types.reduce((o, t) => ({
        ...o, [t]: {
          TimeSlot: null,
      }}), {}),
      date: moment.utc(item.Date),
    });

    setSlots({
      [type]: {},
    })
  };

  useEffect(() => {
    if (loading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [slots]);

  const renderDateRange = () => {
    if (typeof data !== "undefined") {
      if (data?.length > 0 && !slots?.length > 0) {
        return (
          <div className="c-cards-step c-cards-step--1">
            <div className="c-card-timeslots | c-card-timeslots--days">
              {data?.map((item) => {
                return <span key={`${item.Date}-${type}`} onClick={() => {
                  selectSlot(item);
                }} className={`c-card-timeslotsItem ${item.Date === bookingData?.date ? "c-card-timeslotsItem--active" : ""}`}>{formatDate(item.Date).shortDate()}</span>
              })}
            </div>
          </div>
        )
      } else {
        return null;
      }
    } else {
      return loading && <Loader customClass="c-loader--timeslot" />;
    }
  }

  const renderCards = () => {
    return steps[type]?.currentStep === 1 ? renderDateRange() : null;
  }

  return renderCards();
}

export default DateRange;
