import React, { useEffect, useState } from "react";
import { Select } from "./forms/inputs";
import { dailingCodes } from "../utils/dailingCodes";
import validateEmail from '../utils/email';
import { toast } from "react-toastify";
import {format, formatDate} from "../utils/date";
import { useRecoilState, useRecoilValue } from "recoil";
import { setupAtom } from "../atoms/_setup";
import { booking } from "../services/booking";
import { paymentAtom } from "../atoms/_payment";
import { modalState } from "../atoms/_modal";
import InfoModal from "./infoModal";
import Loader from "./loader";
        const validator = (values, setup) => ({
email: !(values?.email && validateEmail(values?.email)),
        emailConfirmation: !(values?.emailConfirmation && validateEmail(values?.emailConfirmation)) || values?.email !== values?.emailConfirmation,
        FirstName: !(values?.FirstName && values?.FirstName.trim() !== ''),
        Surname: !(values?.Surname && values?.Surname.trim() !== ''),
        Mobile: !(values?.Mobile && values?.Mobile.trim() !== ''),
        MobileCountryCode: !(values?.MobileCountryCode && values?.MobileCountryCode.trim() !== ''),
        terms: setup?.TermsAndConditions.length > 0 ? !(values?.terms) : false,
        });
        const CustomerForm = (props) => {
let { widget, translations, type, steps, setSteps, bookingData, setBookingData } = props;
        const setup = useRecoilValue(setupAtom);
        const [payment, setPayment] = useRecoilState(paymentAtom);
        const [_, setModal] = useRecoilState(modalState);
        const [serverErrors, setServerErrors] = useState([]);
        const [loading, setLoading] = useState(false);
        const [errors, setErrors] = useState({
email: false,
        emailConfirmation: false,
        FirstName: false,
        Surname: false,
        Mobile: false,
        MobileCountryCode: false,
        signUp: false,
        terms: false,
});
        const valid = () => {
const formErrors = validator(bookingData?.customer, setup?.[type]);
        return !Object.values(formErrors).some((e) => e);
};
        const handleNextStep = (e, nextStep) => {
e.preventDefault();
const el = document.getElementById(type);
    window.scrollTo(0, el.offsetTop - 100);

        setSteps({
        ...steps,
        [type]: {
        ...steps?.[type],
                currentStep: nextStep,
                nextStep: nextStep + 1,
        },
        })
};
        const handleChange = (e) => {
setErrors({ ...errors, [e.target.name]: false });
        setBookingData({
        ...bookingData,
                customer: {
                ...bookingData?.customer,
                [e.target.name]: e.target.name === "signUp" ? e.target.checked : e.target.value,
                }
        });
};
        const handleSubmit = async (e) => {
e.preventDefault();
        setLoading(true);
        setServerErrors([]);
        var err = [];
        const bookingService = booking(type);
        if (valid()) {
const dataToSend = {
PartySize: bookingData?.partySize,
        VisitDate: format(new Date(bookingData?.[type]?.TimeSlot?.TimeSlot)),
        VisitTime: formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).time,
        IsLeaveTimeConfirmed: bookingData?.IsLeaveTimeConfirmed ? "true" : "false",
        customer: {
        ...bookingData?.customer,
                ReceiveEmailMarketing: bookingData?.customer?.signUp ? "true" : "false",
        },
};
        const bookResponse = await bookingService.book(dataToSend);
        if (bookResponse.Status === "NoAvailability") {
setLoading(false);
        setServerErrors([translations.no_availability])
        return;
}

if (bookResponse.Status === "Success") {
// const result = await bookingService.bookingConfirmation(bookResponse.Booking.Reference);
        setLoading(false);
        handleNextStep(e, steps?.[type].nextStep + 1);
//         if (result.Status === "Success") {
// } else {
// if (result.Message) {
// err.push(result.Message);
// } else {
// err.push(translations.booking_problem + ' ' + setup?.[type].ReservationPhoneNumber);
// }

// if (typeof result?.Errors !== "undefined" && result?.Errors.length > 0) {
// result.Errors.forEach(function (item) {
// err.push(item);
// });
// }

// }
} else if (bookResponse.Status === "CreditCardRequired") {
setLoading(false);
        setPayment({
          ...payment,
        [type]: {
        reference: bookResponse.Booking.Reference,
                stripeKey: bookResponse.Booking.PaymentInformation.StripePublishableKey
        }
        })
        handleNextStep(e, steps?.[type].nextStep);
} else if (bookResponse.Status === "PaymentRequired") {
setLoading(false);
        setPayment({
          ...payment,
        [type]: {
        reference: bookResponse.Booking.Reference,
                stripeKey: bookResponse.Booking.PaymentInformation.StripePublishableKey,
                formattedPaymentAmount: bookResponse.FormattedPaymentAmount,
                depositRequired: bookingData?.[type]?.TimeSlot?.StandardAvailabilityFeeAmount > 0,
        }
        })
        handleNextStep(e, steps?.[type].nextStep);
} else {
setLoading(false);
        err = [translations.booking_problem + ' ' + setup?.[type].ReservationPhoneNumber];
        if (typeof bookResponse?.Errors !== "undefined" && bookResponse?.Errors?.length > 0) {
bookResponse.Errors.forEach(function (item) {
err.push(item);
});
}
}
} else {
setErrors(validator(bookingData?.customer));
        setLoading(false);
        toast.error("Please fill the inputs");
}

setServerErrors([...err]);
};
        const openTermsModal = () => {
const closeModalHandler = () => {
setModal({
showModal: false,
});
};
        setModal({
        showModal: true,
                component: (
                        <InfoModal
    content={setup?.[type]?.TermsAndConditions}
    closeModalHandler={closeModalHandler}
    />
                        ),
        });
};
        useEffect(() => {
        setServerErrors([]);
        }, [steps]);
        const render = () => {
return steps[type]?.currentStep === 2 ? (
        <form className="c-cards-step c-cards-step--2" onSubmit={handleSubmit}>
    <h3>{widget?.label}</h3>
    <p className="c-card-summary">{`${bookingData?.partySize}${" "}${translations.help.people_for} ${formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).shortDate()} ${translations.help.at} ${formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).time}`}</p>
    <div className={`form-group form-group--full ${errors?.FirstName ? "form-group--error" : ""}`}>
        <label className="c-label" htmlFor="FirstName">{translations.label.first_name}</label>
        <input
            name="FirstName"
            id="FirstName"
            className={`c-input ${errors?.FirstName ? "c-input--error" : ""}`}
            type="text"
            value={bookingData?.customer?.FirstName}
            onChange={handleChange} />
    </div>
    <div className={`form-group form-group--full ${errors?.Surname ? "form-group--error" : ""}`}>
        <label className="c-label" htmlFor="Surname">{translations.label.last_name}</label>
        <input
            name="Surname"
            id="Surname"
            className={`c-input ${errors?.Surname ? "c-input--error" : ""}`}
            type="text"
            value={bookingData?.customer?.Surname}
            onChange={handleChange} />
    </div>
    <div className="form-groups">
        <div className={`form-group ${errors?.MobileCountryCode ? "form-group--error" : ""}`}>
            <Select
                error={errors?.MobileCountryCode}
                name="MobileCountryCode"
                label={translations.label.country_code}
                defaultValue={bookingData?.customer?.MobileCountryCode}
                onChange={handleChange}
                options={dailingCodes}
                />
        </div>
        <div className={`form-group ${errors?.Mobile ? "form-group--error" : ""}`}>
            <label className="c-label" htmlFor="Mobile">{translations.label.phone}</label>
            <input
                name="Mobile"
                id="Mobile"
                className={`c-input ${errors?.Mobile ? "c-input--error" : ""}`}
                type="tel"
                value={bookingData?.customer?.Mobile}
                onChange={handleChange} />
        </div>
    </div>
    <div className={`form-group form-group--full ${errors?.email ? "form-group--error" : ""}`}>
        <label className="c-label" htmlFor="email">{translations.label.email}</label>
        <input
            name="email"
            id="email"
            className={`c-input ${errors?.email ? "c-input--error" : ""}`}
            type="email"
            value={bookingData?.customer?.email}
            onChange={handleChange} />
    </div>
    <div className={`form-group form-group--full ${errors?.emailConfirmation ? "form-group--error" : ""}`}>
        <label className="c-label" htmlFor="emailConfirmation">{translations.label.confirm_email}</label>
        <input
            name="emailConfirmation"
            id="emailConfirmation"
            className={`c-input ${errors?.emailConfirmation ? "c-input--error" : ""}`}
            type="email"
            value={bookingData?.customer?.emailConfirmation}
            onChange={handleChange} />

        {bookingData?.customer?.emailConfirmation !== bookingData?.customer?.email ? <p className="form-group-validationFeedback">{translations.errors.email_no_match}</p> : null}

    </div>
    <div className="form-group form-group--full">
        <label className={`c-label c-checkbox ${errors?.signUp ? "c-checkbox--error" : ""}`}>
            <input
                onChange={handleChange}
                className="c-checkboxInput"
                type="checkbox"
                checked={bookingData?.customer?.signUp}
                value={true}
                name="signUp" />
            {translations.label.sign_up_newsletter}
        </label>
    </div>
    {setup?.[type]?.TermsAndConditions?.length > 0 ?
        (
                <div className="form-group form-group--full">
        <label className={`c-label c-checkbox ${errors?.terms ? "c-checkbox--error" : ""}`}>
            <input
                onChange={handleChange}
                className="c-checkboxInput"
                type="checkbox"
                checked={bookingData?.customer?.terms}
                value={true}
                name="terms" />
            {translations.label.terms_conditions}
        </label>
        <span className="c-link" onClick={openTermsModal}>{translations.label.read_terms}</span>
        <p className={`form-group-validationFeedback ${errors?.terms ? "form-group-validationFeedback--visible" : ""}`}>
            {translations.errors.terms_agreement}
        </p>
    </div>
                ) : null
    }

    {setup?.[type]?.StandardAvailabilityMayRequireCreditCard ? (<div className="form-group form-group--center form-group--full"><strong>{translations.help.credit_card_required}</strong></div>) : null}

    <div>{serverErrors?.length > 0 ? (
        <>
        {serverErrors?.map((err, index) => (
                <p className="u-text-danger" key={`err-${index}`}>{err}</p>
                ))}
        </>
        )
        : null
        }</div>

        <div className="c-buttons">
        {!loading ? (
                <>
        <button
            onClick={(e) => {
        handleNextStep(e, steps?.[type].currentStep - 1);
        }}
            className="c-button">{translations.label.back}
        </button>
        <button
            className="c-button">{translations.label.complete_booking}
        </button>
        </>
        ) : <Loader content="Please wait..." customClass="c-loader--buttons" />}
    </div>
                </form>
                ) : null;
};

return render();
}

export default CustomerForm;
