import React from "react";
import { formatDate }
from "../utils/date";

const Success = (props) => {
    let {type, steps, bookingData, translations} = props;

    const renderSuccess = () => {
        if (steps?.[type]?.currentStep === 4) {
            if(!!document){
                document.dispatchEvent(new CustomEvent('callback_to_success'));
            }
            return (
                <div className="c-cards-step c-cards-step--4">
                    <h3>{translations.label.booking_complete}</h3>
                    <p className="c-card-summary">{`${bookingData?.partySize}${" "}${translations.help.people_for} ${formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).shortDate()} ${translations.help.at} ${formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).time}`}</p>
                </div>
            )
        } else {
            return null;
        }
    }

    return renderSuccess();
}

export default Success;
