export const handleResponse = (response) => {
  switch (response.status) {
    case 200:
      return response.data;
    case 201:
      return response.data;
    case 204:
      return true;
    default:
      console.log('Unhandled response!', response);
  }
};

const handleMultipleErrors = (errors) => {
  if (errors) {
    for (let key in errors) {
      if (errors.hasOwnProperty(key)) {
        console.log(errors[key].msg);
      }
    }
  }
};

export const handleError = (error) => {
  if (error.response && error.response.data) {
    if (error.response.data.error) {
      console.log(error.response.data.error);
    } else if (error.response.data.message) {
      console.log(error.response.data.message);
    } else if (error.response.data.errors) {
      handleMultipleErrors(error.response.data.errors);
    } else {
      console.log(error.message);
    }
  } else {
    console.log(error.message);
  }
};
