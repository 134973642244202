import React, {useState} from "react";
import { formatDate, trimTime } from "../utils/date";
import Loader from "./loader";

const Timeslots = (props) => {
  let { setup, widget, data, translations, partySize, type, steps, setSteps, bookingData, setBookingData } = props;

  const [kitchenClose, setKichenClose] = useState(false);

  const [leaveStatementError, setLeaveStatementError] = useState(false);

  const selectSlot = (item) => {
    setSteps({
      ...steps,
      [type]: {
        ...steps?.[type],
        isSlotSelected: true,
      },
    });

    setBookingData({
      ...bookingData,
      [type]: {
        TimeSlot: item,
      }
    })

    if (formatDate(item.TimeSlot).trimedTime >= 22) {
      setKichenClose(true);
    } else {
      setKichenClose(false);
    }
  };

  const onStatementChange = (e) => {
    setBookingData({
      ...bookingData,
      IsLeaveTimeConfirmed: e.target.checked
    });
  };

  const handleNextStep = (e, nextStep) => {
    e.preventDefault();
    const el = document.getElementById(type);

    if (bookingData?.[type]?.TimeSlot?.IsLeaveTimeRequired) {
      if (bookingData?.IsLeaveTimeConfirmed !== true) {
        setLeaveStatementError(true);
      } else {
        window.scrollTo(0, el.offsetTop - 100);
        setLeaveStatementError(false);
        setSteps({
          ...steps,
          [type]: {
            ...steps?.[type],
            currentStep: nextStep,
            nextStep: nextStep + 1,
          },
        })
      }
    } else {
      window.scrollTo(0, el.offsetTop - 100);
      setSteps({
        ...steps,
        [type]: {
          ...steps?.[type],
          currentStep: nextStep,
          nextStep: nextStep + 1,
        },
      })
    }
  };

  const renderTimeslots = () => {
    if (typeof data !== "undefined") {
      if (data?.length > 0) {
        return (
          <div className="c-cards-step c-cards-step--1">
          <div className="c-card-timeslots">
            {data?.map((item) => {
              return <span key={item?.TimeSlot} onClick={() => {
                selectSlot(item);
              }} className={`c-card-timeslotsItem ${item?.TimeSlot === bookingData?.[type]?.TimeSlot?.TimeSlot ? "c-card-timeslotsItem--active" : ""}`}>{formatDate(item?.TimeSlot).time}</span>
            })}
          </div>
          {bookingData?.[type]?.TimeSlot && Object.keys(bookingData?.[type]?.TimeSlot).length !== 0 && (
            <>
              {bookingData?.[type]?.TimeSlot?.IsLeaveTimeRequired ? <p>{translations.help.table_at}{" "}{trimTime(bookingData?.[type]?.TimeSlot?.LeaveTime)}</p> : null}

              {bookingData?.[type]?.TimeSlot?.StandardAvailabilityFeeAmount > 0 ? (
                <div className="form-group form-group--full form-group--center">{translations.help.deposit_of + " " + setup?.[type]?.CurrencySymbol + "" + bookingData?.[type]?.TimeSlot?.StandardAvailabilityFeeAmount + " " + translations.help.required_secure_booking}</div>
                ) : null}

              {bookingData?.[type]?.TimeSlot?.IsLeaveTimeRequired ? <div className="form-group form-group--full form-group--center"><label className={`c-checkbox ${leaveStatementError && !bookingData?.IsLeaveTimeConfirmed ? "c-checkbox--error" : null}`}><input checked={bookingData?.IsLeaveTimeConfirmed} onChange={onStatementChange} className="c-checkboxInput" type="checkbox" name="terms" />{translations.label.leave_time_statement}</label></div> : null}

              {setup?.[type]?.StandardAvailabilityMayRequireCreditCard ? (<div className="form-group form-group--full form-group--center"><strong>{translations.help.credit_card_required}</strong></div>) : null}

              <p className="c-card-summary">{`${partySize}${" "}${translations.help.people_for} ${formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).shortDate()} ${translations.help.at} ${formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).time}`}</p>
              <p className="c-card-summary">{widget.t.help.service_price}</p>
            </>
          )}

          {kitchenClose ? <p className="u-text-danger">{translations.help.arrival}</p> : null}

          {steps?.[type]?.isSlotSelected ? (
            <button
            onClick={(e) => {
              handleNextStep(e, steps?.[type]?.nextStep);
            }}
            className="c-button">{translations.label.next}
            </button>
          ) : null}
        </div>
      )
      } else {
        return widget?.noavailability || "Loading";;
      }
    } else {
      return <Loader customClass="c-loader--timeslot" />
    }
  }

  const renderCards = () => {
    return steps[type]?.currentStep === 1 ? renderTimeslots() : null;
  }

  return renderCards();
}

export default Timeslots;
