import axios from "./index";
import { handleResponse, handleError } from "./responseHandler";
import {currentDateFormatted} from "../utils/date";
import moment from "moment";

const route = "restaurant";

const formattedDate = (date) => {
  return new Date(date).toISOString().replace(/T.*/,'').split('-').join('-');
};

export let availability = (microsite, date) => {
  let reservationDate = formattedDate(date) || currentDateFormatted();

  let setup = () => {
    return axios.get(`${route}/${microsite}/setup/${reservationDate}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
  };

  let get = (partySize) => {
    return axios.get(`${route}/${microsite}/availability/${reservationDate}/${partySize}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
  };

  let getRange = (startDate, endDate, partySize) => {
    return axios.get(`${route}/${microsite}/availabilityrange/${startDate}/${endDate}/${partySize}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
  };

  return {
    setup,
    get,
    getRange,
  }
};
