
import React, {useState, useRef, useEffect} from "react";
import useOnClickOutside from "use-onclickoutside";

export function Select({
  options,
  name,
  label,
  error,
  defaultValue,
  placeholder,
  disabled,
  onChange = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);

  // Use for trigger render so we can collect values and rerender placeholder.
  const [__, setChangeHappend] = useState(Math.random());

  const subscribe = useRef(false);

  const ref = useRef(null);

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    subscribe.current = true;
  }, []);

  const close = () => {
    setIsOpen(false);
  };

  useOnClickOutside(ref, close);

  const handleClick = () => {
    setChangeHappend(Math.random());
  };

  const renderPlaceholder = () => {
    if (!subscribe.current && !defaultValue) {
      return placeholder;
    }

    let buttonLabel = placeholder;

    options.forEach(function (item) {
      if (item.value?.toString() === defaultValue?.toString()) {
        buttonLabel = name === "MobileCountryCode" ? `(${item?.value}) ${item.label}` : item.label;
      }
    });

    return buttonLabel;
  };

  const renderButton = () => {
    return (
      <button
        type="button"
        disabled={disabled}
        className={`c-dropdown-button`}
        onClick={toggle}
      >
        {renderPlaceholder()}
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path className="path" d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
      </button>
    );
  };

  if (!options.length) return null;

  return (
    <>
      <label className="c-label c-label--uppercase">{label}</label>

      <div
        ref={ref}
        className={`c-dropdown ${isOpen ? "c-dropdown--shown" : ""} ${error &&
          "c-dropdown--error"}`}
      >
        {renderButton()}
        <div className="c-dropdown-menu | more"
            data-lenis-prevent="true"
        >
          {options.map((item, index) => {
            const found = item?.value?.toString() === defaultValue?.toString();
            return (
              <label
                onClick={handleClick}
                className={`c-dropdown-menuItem ${
                  found ? "c-dropdown-menuItem--selected" : ""
                }`}
                htmlFor={item.value}
                key={item.value}
              >
                <input
                  name={name}
                  onChange={(e) => {
                    toggle();
                    onChange(e) }}
                  id={item.value}
                  key={item.value}
                  value={item.value}
                  disabled={item.disabled}
                  type="radio"
                  defaultChecked={defaultValue === item.value}
                  className="c-dropdown-menuItemInput"
                />
                  {name === "MobileCountryCode" ? `(${item?.value}) ${item?.label}` : item?.label}
              </label>
            );
          })}
        </div>
      </div>
      <p className="form-group-validationFeedback">{error?.message}</p>
    </>
  );
}
