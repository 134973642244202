import axios from "./index";
import { handleResponse, handleError } from "./responseHandler";

const route = "restaurant";

export let booking = (microsite) => {
    let book = (data) => {
        const {customer: Customer, ...doc} = data || {}
        const safeData = {
            ...doc,
            ...Customer && {Customer: {...Customer, ReceiveSmsMarketing: "false", MobileCountryCode: Customer.MobileCountryCode && Customer.MobileCountryCode.replace('+', ''), Mobile: Customer.Mobile && Customer.Mobile.replaceAll(' ', '').replace(/\D/g, '')}}
        }
        return axios.post(`${route}/${microsite}/booking`, {bookingData: safeData})
                .then((response) => {
                    return handleResponse(response);
                })
                .catch((error) => {
                    handleError(error);
                });
    };

    let bookingConfirmation = (reference, data) => {
        return axios.post(`${route}/${microsite}/booking/confirm/${reference}`, {...data})
                .then((response) => {
                    return handleResponse(response);
                })
                .catch((error) => {
                    handleError(error);
                });
    }

    return {
        book,
        bookingConfirmation,
    }
};
