import React from "react";
import { useRecoilValue } from "recoil";
import { setupAtom } from "../atoms/_setup";

const Widget = (props) => {
  const setup = useRecoilValue(setupAtom);

  let data = props?.data;
  let currentStep = props?.currentStep;

  const renderContent = () => {
    return (
      <div
      dangerouslySetInnerHTML={{__html: data?.blockcontent}}>
      </div>
    )
  };

  const renderWidget = () => {
    return (
      <div id={data?.microsite} className={`c-card c-card--${currentStep}`}>
        {currentStep < 2 ? renderContent() : null}
        {props.children}
      </div>
    );
  };

  return renderWidget();
}

export default Widget;
