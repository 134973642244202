import React, { useEffect, useState, useRef } from "react";

import CustomerForm from "../datacomponents/customerForm";
import DateRange from "../datacomponents/dateRange";
import { Select } from "../datacomponents/forms/inputs";
import Payment from "../datacomponents/payment";
import Timeslots from "../datacomponents/timeslots";
import Widget from "../datacomponents/widget";
import { availability } from "../services/availability";
import { widget } from "../services/widget";
import { settings } from "../services/settings";
import { useRecoilState } from "recoil";
import { SingleDatePicker } from "react-dates";
import { buildDatesArray } from "../utils/date";
import Loader from "../datacomponents/loader";
import { setupAtom } from "../atoms/_setup";
import Success from "../datacomponents/success";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/fr";
import "moment/locale/en-in";

const dateFormat = "DD/MM/YYYY";

const Booking = () => {
  let paramsString = window.location.search;

  let searchParams = new URLSearchParams(paramsString);

  let availabilitySetupPromises = [];
  let availabilityPromises = [];
  let availabilityRangePromises = [];

  let mommentInstance = moment;

  const [bookingData, setBookingData] = useState({
    date: "",
    IsLeaveTimeConfirmed: false,
  });

  const [minMax, setMinMax] = useState({
    min: null,
    max: null,
  });

  const filtersRef = useRef(null);
  const [lang, setLang] = useState();
  const [setup, setDataOptions] = useRecoilState(setupAtom);
  const [steps, setSteps] = useState({});
  const widgetFactory = widget();
  const [translations, setTranslations] = useState({});
  const [widgets, setWidgets] = useState([]);
  const [slots, setSlots] = useState({});
  const [availabilityRange, setAvailabilityRange] = useState({});
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let language = "en";
    let settingsInstance = settings();
    let promises = [];
    language = document.documentElement.getAttribute("lang");
    mommentInstance.locale(language);

    setLang(language);

    setBookingData(({
      ...bookingData,
      date: searchParams.get("date") ? mommentInstance(searchParams.get("date")).utc(true) : mommentInstance.utc()
    }));

    widgetFactory.setBaseURL(language);

    settingsInstance.setup().then(data => {
      data.bookingTypes.forEach(function(item) {
        promises.push(widgetFactory.setup(item?.configid));
      });
      Promise.all(promises).then(values => {
        setWidgets(
          values.map((value, index) => ({
            ...value,
            microsite: data?.bookingTypes[index].microsite,
          })),
        );
        setTranslations({
          ...values[0].t,
          pageTitle: data?.pageTitle,
          introText: data?.introText
        });
      });
    });
  }, []);

  useEffect(() => {
    if (typeof bookingData?.date !== "undefined") {
      if (widgets.length > 0) {
        widgets.map(item => {
          availabilitySetupPromises.push(
            availability(item?.microsite, bookingData?.date).setup(
              bookingData?.date,
            ),
          );
        });
        Promise.all(availabilitySetupPromises).then(values => {
          setDataOptions(
            values?.reduce(
              (o, item, index) => ({
                ...o,
                [widgets[index]?.microsite]: {
                  ...setup?.[widgets[index]?.microsite],
                  ...item,
                },
              }),
              {},
            ),
          );
          let OnlinePartySizeDefault = values.reduce(function(a, b) {
              return a.OnlinePartySizeDefault;
          });
          setBookingData({
            ...bookingData,
            partySize: searchParams.get("size") || bookingData?.partySize || OnlinePartySizeDefault,
          });
        });
        setSteps(
          widgets?.reduce(
            (o, item) => ({
              ...o,
              [item?.microsite]: {
                currentStep: 1,
                nextStep: 2,
              },
            }),
            {},
          ),
        );
      }
    }
  }, [bookingData?.date, widgets]);
  useEffect(() => {
    if (
      typeof bookingData?.partySize !== "undefined" &&
      typeof bookingData?.date !== "undefined"
    ) {
      widgets.map(item => {
        availabilityPromises.push(
          availability(item?.microsite, bookingData?.date).get(
            bookingData?.partySize,
          ),
        );
      });
      Promise.all(availabilityPromises).then(data => {
        setDataOptions(
          data?.reduce(
            (o, item, index) => ({
              ...o,
              [widgets[index]?.microsite]: {
                ...setup?.[widgets[index]?.microsite],
                ...item,
                StandardAvailabilityMayRequireCreditCard:
                  item?.StandardAvailabilityMayRequireCreditCard,
              },
            }),
            {},
          ),
        );
        setSlots(
          data?.reduce(
            (o, item, index) => ({
              ...o,
              [widgets[index]?.microsite]: {
                ...item,
              },
            }),
            {},
          ),
        );
      });
    }
  }, [bookingData?.partySize, bookingData?.date]);

  useEffect(() => {
    if (Object.keys(slots).length !== 0) {
      availabilityRangePromises = [];
      widgets.map(item => {
        if (
          typeof bookingData?.partySize !== "undefined"
          ) {

            let dates = buildDatesArray(bookingData?.date, item.range_start_date, item.range_end_date);

          availabilityRangePromises.push(
            availability(item?.microsite, bookingData?.date).getRange(
              dates[0],
              dates[dates.length - 1],
              bookingData?.partySize,
            ),
          );
        }
      });
      if (availabilityRangePromises.length >= 2) {
        Promise.all(availabilityRangePromises).then(data => {
          setAvailabilityRange(
            data?.reduce(
              (o, item, index) => ({
                ...o,
                [widgets[index]?.microsite]: {
                  ...item,
                },
              }),
              {},
            ),
          );
        });
      }
    }
  }, [slots, widgets]);
  const resetOnChange = () => {
    setSteps(
      widgets?.reduce(
        (o, item) => ({
          ...o,
          [item?.microsite]: {
            currentStep: 1,
            nextStep: 2,
          },
        }),
        {},
      ),
    );
    setSlots(
      widgets?.reduce(
        (o, item) => ({
          ...o,
          [item?.microsite]: {},
        }),
        {},
      ),
    );
    setAvailabilityRange(
      widgets?.reduce(
        (o, item) => ({
          [item?.microsite]: {
            AvailableDates: [],
          },
        }),
        {},
      ),
    );
  };
  const handleDate = d => {
    resetOnChange();
    setFocused(false);
    setBookingData(
      widgets?.reduce(
        (o, item) => ({
          date: d,
          partySize: bookingData?.partySize,
          IsLeaveTimeConfirmed: false,
          [item?.microsite]: {
            TimeSlot: {},
          },
        }),
        {},
      ),
    );
  };
  const handleChange = e => {
    resetOnChange();
    setBookingData(
      widgets?.reduce(
        (o, item) => ({
          date: bookingData?.date,
          partySize: bookingData?.partySize,
          IsLeaveTimeConfirmed: false,
          [e.target.name]: e.target.value,
          [item?.microsite]: {
            TimeSlot: {},
          },
        }),
        {},
      ),
    );
  };

  const getMinMax = () => {
    const keys = Object.keys(setup);
    const minAndMax = keys.reduce(function(a, b) {
      return {
          min: setup[a].MinOnlinePartySize,
          max: setup[a].MaxOnlinePartySize
      };
    });
    setMinMax({
      ...minAndMax,
    });
  };

  useEffect(() => {
    if (Object.keys(setup).length > 0) {
      getMinMax();
    }
  }, [setup]);

  const renderContent = (intro) => {
    return (
      <div
      dangerouslySetInnerHTML={{__html: intro}}>
      </div>
    )
  };

  return (
    <>
      <div className="bookings-intro">
        {/* <h2 className="serif">{translations?.pageTitle}</h2> */}
        {renderContent(translations?.introText)}
      </div>
      <div className="form-filters" ref={filtersRef}>
        <div className="form-group">
          {typeof bookingData?.date !== "undefined" &&
          Object.keys(translations).length > 0 ? (
            <>
              <label>{translations.label.date}</label>
              <SingleDatePicker
                withPortal={window.innerWidth < 768}
                readOnly
                displayFormat={dateFormat}
                numberOfMonths={1}
                keepOpenOnDateSelect={true}
                placeholder={translations.label.enter_date}
                date={bookingData?.date}
                onDateChange={handleDate}
                focused={focused}
                hideKeyboardShortcutsPanel={true}
                onFocusChange={data => {
                  setFocused(data?.focused);
                }}
                id="date"
              />
            </>
          ) : (
            <Loader customClass="c-loader--input" />
          )}
        </div>
        <div className="form-group">
            {typeof bookingData?.partySize !== "undefined" ? (
            <Select
              name="partySize"
              label={widgets?.[0]?.pslabel}
              defaultValue={bookingData?.partySize}
              onChange={handleChange}
              options={widgetFactory.buildPartySizesArrayByMax(
                minMax?.min,
                minMax?.max,
              )}
            />
          ) : (
            <Loader customClass="c-loader--input" />
          )}
        </div>
      </div>

    <div class="an-extra-info is-en u-none">Are you 8 or more people? Then write to us at <a target="_blank" href="mailto:reservations@aiyannaibiza.com">reservations@aiyannaibiza.com</a></div>
    <div class="an-extra-info is-es u-none">¿Vais a ser 8 o más personas? Entonces escríbenos a <a target="_blank" href="mailto:reservations@aiyannaibiza.com">reservations@aiyannaibiza.com</a></div>
      

      <div className="c-cards">
        {widgets.length > 0 ? (
          widgets.map((resWidget, index) => {
            return (
              <Widget
                key={`${resWidget?.microsite}-${index}`}
                currentStep={steps[resWidget?.microsite]?.currentStep}
                data={resWidget}
              >
                <Timeslots
                  setup={setup}
                  widget={resWidget}
                  bookingData={bookingData}
                  setBookingData={setBookingData}
                  type={resWidget?.microsite}
                  steps={steps}
                  setSteps={setSteps}
                  partySize={bookingData?.partySize}
                  translations={translations}
                  data={slots[resWidget?.microsite]?.TimeSlots}
                />
                <DateRange
                  moment={mommentInstance}
                  bookingData={bookingData}
                  setBookingData={setBookingData}
                  type={resWidget?.microsite}
                  types={widgets.map((wid) => wid.microsite)}
                  steps={steps}
                  slots={slots[resWidget?.microsite]?.TimeSlots}
                  setSlots={setSlots}
                  data={availabilityRange[resWidget?.microsite]?.AvailableDates}
                />
                <CustomerForm
                  widgets={widgets}
                  widget={resWidget}
                  translations={translations}
                  bookingData={bookingData}
                  setBookingData={setBookingData}
                  type={resWidget?.microsite}
                  steps={steps}
                  slots={slots}
                  setSteps={setSteps}
                />
                <Payment
                  key="payment"
                  widget={resWidget}
                  translations={translations}
                  bookingData={bookingData}
                  setBookingData={setBookingData}
                  type={resWidget?.microsite}
                  steps={steps}
                  setSteps={setSteps}
                  language={lang}
                />
                <Success
                  steps={steps}
                  key="success"
                  widget={resWidget}
                  translations={translations}
                  bookingData={bookingData}
                  type={resWidget?.microsite}
                />
              </Widget>
            );
          })
        ) : (
          <>
            <div className="resdiary-booking-panel c-card c-card">
              <Loader customClass="c-loader--widget" />
            </div>
            <div className="resdiary-booking-panel c-card c-card">
              <Loader customClass="c-loader--widget" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Booking;
