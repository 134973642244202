import React from "react";

import Modal from "react-modal";
import { useRecoilState } from "recoil";
import { modalState } from "../atoms/_modal";

const ModalComponent = () => {
  const [modal, setModal] = useRecoilState(modalState);

  const closeModalHandler = () => {
    setModal(false);
  };

  return (
    <Modal
      ariaHideApp={false}
      className="modalContent"
      overlayClassName="modalOverlay"
      isOpen={modal.showModal}
    >
      <div className="modalContent-dialog modalContent-dialog--confirmation">
        <div className="modalContent-dialogHeader">
          <button
            onClick={closeModalHandler}
          ></button>
        </div>

        {modal.component}
      </div>
    </Modal>
  );
};

export default ModalComponent;
