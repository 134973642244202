import React from "react";
import { formatDate } from "../utils/date";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useRecoilValue } from "recoil";
import PaymentStripe from "./paymentStripe";
import { paymentAtom } from "../atoms/_payment";
        const Payment = (props) => {
let {widget, translations, type, steps, bookingData, language} = props;
        const payment = useRecoilValue(paymentAtom);
        if (!payment?.[type]?.stripeKey) {
return null;
        }

const stripePromise = loadStripe(payment?.[type]?.stripeKey || "", {locale: language});
        const render = () => {
return steps[type]?.currentStep === 3 ? (
        <Elements stripe={stripePromise}>
    <div className="c-cards-step c-cards-step--3">
        <h3>{widget?.label}</h3>
        <p className="c-card-summary">{`${bookingData?.partySize}${" "}${translations.help.people_for} ${formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).shortDate()} ${translations.help.at} ${formatDate(bookingData?.[type]?.TimeSlot?.TimeSlot).time}`}</p>
        <div>
            {payment?.[type]?.depositRequired ? (
        <p>
                { translations.help.deposit_intro } <strong>{payment?.[type]?.formattedPaymentAmount}</strong>. { translations.help.deposit_fill_in }
            </p>
        ) :
        <p>{ translations.help.deposit_cancellation_policy }</p>
            }
        </div>
        <PaymentStripe {...props} />
    </div>
</Elements>
        ) : null;
        };
        return render();
}

export default Payment;
