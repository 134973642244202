import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { RecoilRoot } from "recoil";

import * as serviceWorker from './serviceWorker';

// Función para renderizar la aplicación
const renderApp = () => {
    ReactDOM.render(
        <React.StrictMode>
            <RecoilRoot>
                <App />
            </RecoilRoot>
        </React.StrictMode>,
        document.getElementById('root')
    );
};
    

// Agregamos un escucha de eventos para "callback_for_react"
document.addEventListener('callback_for_react', () => {
    // Llamamos a la función para renderizar la aplicación nuevamente
    // console.log('dentro de callback for react');
    renderApp();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
