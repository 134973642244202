import axios from "./index";
import { handleResponse, handleError } from "./responseHandler";

const route = "configbyid";

export let widget = () => {
  const setBaseURL = (lang) => {
    axios.defaults.baseURL = `${axios.defaults.baseURL}`;
  };

  const setup = (configId) => {
    let lan = document.documentElement.getAttribute("lang");
    return axios.get(`${lan}/${route}/${configId}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
  };


  const buildPartySizesArrayByMax = (min, max) => {
    let arr = [];
    for (let index = min; index <= max; index++) {
      arr[index] = {
        label: index,
        value: index,
      };
    }

    return arr;
  }

  return {
    setBaseURL,
    setup,
    buildPartySizesArrayByMax,
  }
};
