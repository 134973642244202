import axios from "./index";
import { handleResponse, handleError } from "./responseHandler";

const routeLanguage = "language";
const routeSettings = "settings";

export let settings = () => {
  const getLanguage = () => {
    return axios.get(`${routeLanguage}`)
    .then((response) => {
      return handleResponse(response?.data);
    })
    .catch((error) => {
      handleError(error);
    });
  };

  const setup = () => {
    return axios.get(`${routeSettings}`)
    .then((response) => {
      return handleResponse(response?.data);
    })
    .catch((error) => {
      handleError(error);
    });
  };

  return {
    getLanguage,
    setup,
  }
};
