import React, { useState } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useRecoilValue } from "recoil";
import { paymentAtom } from "../atoms/_payment";
import { booking } from "../services/booking";
import { toast } from "react-toastify";
import { setupAtom } from "../atoms/_setup";
import Loader from "./loader";

const PaymentStripe = (props) => {
  let { translations, type, steps, setSteps } = props;
  const [errors, setErrors] = useState([]);
  const [termsconfirmed, setTermsconfirmed] = useState({});

  const [loading, setLoading] = useState(false);

  const payment = useRecoilValue(paymentAtom);
  const setup = useRecoilValue(setupAtom);

  const stripe = useStripe();
  const elements = useElements();

  const handleNextStep = async (e, nextStep) => {
    e.preventDefault();

    if (!stripe && !elements) {
      return;
    }

    setLoading(true);

    const bookingService = booking(type);

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.createToken(cardElement);
    console.log(result);

    if (result.error) {
      toast.error(result?.error?.message);
      setLoading(false);
    }

    if (result.token) {
      if (result.token?.id) {
        bookingService.bookingConfirmation(payment?.[type]?.reference, {
          stripeToken: result?.token?.id
        }).then((data) => {
          setLoading(false);
          if (data?.Message !== "") {
            let err = [translations.booking_problem + ' ' + setup?.[type].ReservationPhoneNumber];
            if (typeof data?.Errors !== "undefined" && data?.Errors.length > 0) {
              data.Errors.forEach(function (item) {
                err.push(item);
              });
            }
            setErrors(err);
          }

          if (data.Status === "Success") {
            const el = document.getElementById(type);
            window.scrollTo(0, el.offsetTop - 100);
            setSteps({
              ...steps,
              [type]: {
                ...steps?.[type],
                currentStep: nextStep,
                nextStep: nextStep + 1,
              },
            });
          }

        });
      }
    }
  };

  const handleChange = (e) => {
    setTermsconfirmed({
      [type]: {
        termsconfirmed: e.target.checked
      }
    })
  }

  const render = () => {
    return (
      <>
        <div className="form-group form-group--full">
          <CardElement />
        </div>

        {!payment?.[type]?.depositRequired ? (
        <div className="form-group form-group--full">
          <label className={`c-label c-checkbox`}>
            <input
              onChange={handleChange}
              className="c-checkboxInput"
              type="checkbox"
              checked={termsconfirmed?.[type]?.termsconfirmed}
              value={true}
              name="termsconfirmed" />
              {translations.label.cancellation_policy}
          </label>
        </div>
        ) : null}

        <div>{errors?.length > 0 ? (
        <>
          {errors?.map((err) => (
            <p className="u-text-danger">{err}</p>
          ))}
        </>
        )
        : null
        }</div>
        <div className="c-buttons">
          {!loading ? (
            <>
              <span
                onClick={(e) => {
                  const el = document.getElementById(type);
                  window.scrollTo(0, el.offsetTop - 100);
                  setSteps({
                    ...steps,
                    [type]: {
                      ...steps?.[type],
                      currentStep: steps?.[type].currentStep - 1,
                      nextStep: steps?.[type].currentStep,
                    },
                  });
                }}
                className="c-button">{translations.label.back}
              </span>
              {payment?.[type]?.depositRequired ? (
                <button
                  onClick={(e) => {
                    handleNextStep(e, steps?.[type].nextStep);
                  }}
                  className="c-button">{translations.label.next}
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    handleNextStep(e, steps?.[type].nextStep);
                  }}
                  className="c-button" disabled={!termsconfirmed?.[type]?.termsconfirmed}>{translations.label.next}
                </button>
              )}
            </>
            ) : <Loader content="Please wait..." customClass="c-loader--buttons" />
          }
        </div>
      </>
    )
  };

  return render();
}

export default PaymentStripe;
